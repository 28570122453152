<template>
    <div class="page_content">
        <div class="page_edit_content page_detail">
			<div class="title_h1">
                结算单
            </div>
            <div>
                <a-descriptions :column="2">
					<!-- 只有从已结算列表中点进来才会显示“结算状态”字段，status:2 已结算 -->
                    <a-descriptions-item label="结算状态" v-if="info.status == 2">
                        {{ info.is_abnormal ? '异常结算' : '正常结算'}}
                    </a-descriptions-item>
					<!-- 只有异常结算才会有结算原因 -->
                    <a-descriptions-item label="结算原因" v-if="info.is_abnormal">
                        {{ info.reason }}
                    </a-descriptions-item>
                    <a-descriptions-item label="对账单位">
                        {{ info.account_unit }}
                    </a-descriptions-item>
                    <a-descriptions-item label="联系电话">
                        {{ info.contact_phone }}
                    </a-descriptions-item>
                    <a-descriptions-item label="对账日期">
                        {{ info.account_time }}
                    </a-descriptions-item>
                    <a-descriptions-item label="关联发放记录">
                        <a @click="grant_link()">{{ info.grant_link }}</a>
                    </a-descriptions-item>
                </a-descriptions>
                <a-table
                    class="mt10"
                    id="table"
                    :columns="columns"
                    :pagination="false"
                    :dataSource="info.goods_data"
                >
                    <template
                        slot="actual_num"
                        slot-scope="text, record, index"
                    >
                        <a-input
                            style="width: 100px"
                            v-model="goods_form[index].value"
                        />
                    </template>
                </a-table>
                <a-descriptions :column="2" style="margin-top: 15px">
                    <a-descriptions-item label="结算人">
                        {{ info.username }}
                    </a-descriptions-item>
                    <a-descriptions-item v-if="info.status !== 2 && info.status !== 4 && info.is_self" label="结算操作">
                        <a-select
                            
                            :style="{
                                minWidth: '200px',
                            }"
                            v-model="operating_index"
                            @change="operating_change"
                        >
                            <a-select-option
                                v-for="vo in operating"
                                :key="vo.key"
                                :title="vo.value"
                                :value="vo.key"
                                >{{ vo.value }}</a-select-option
                            >
                        </a-select>
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <div class="box_form box_form_btns">
                <a-button
                    type="defalut"
                    @click="back()"
                    style="margin-right: 10px"
                    >取消</a-button
                >
                <a-button  v-if="info.status !== 2 && info.status !== 4 && info.is_self"  type="primary" @click="handleSubmit">确定</a-button>
            </div>
        </div>
    </div>
</template>

<script>
import LookImages from "@/components/LookImages";
import { settlementDetail, beginSettle } from '@/api/cost'
import Form from "@/components/Form";
const columns = [
    {
        title: "品项",
        dataIndex: "goods_title",
    },
    {
        title: "数量",
        dataIndex: "num",
    },

];

const columns_operating = [
    {
        title: "品项",
        dataIndex: "goods_title",
    },
    {
        title: "数量",
        dataIndex: "num",
    },
    {
        title: "更改后数量",
        dataIndex: "user_num",
    },
    {
        title: "更改人",
        dataIndex: "username",
    },
    {
        title: "更改后数量",
        dataIndex: "customer_num",
    },
    {
        title: "更改人",
        dataIndex: "customer_name",
    },
]

const columns_abnormal = [
    {
        title: "品项",
        dataIndex: "goods_title",
    },
    {
        title: "数量",
        dataIndex: "num",
    },
    {
        title: "实际数量",
        dataIndex: "actual_num",
        scopedSlots: {
            customRender: "actual_num"
        }
    },
];
export default {
    components: {
        LookImages,
        Form
    },
    data() {
        return {
            columns,
            operating_index: 1,
            operating: [
                {
                    key: 1,
                    value: '确认结算'
                },
                {
                    key: 2,
                    value: '异常帐单'
                }
            ],
            info: {
                goods_data: []
            },
            goods_form: []

        }
    },
    created() {
        this.get_info()

        if (this.$route.query.type == 'abnormal') {
            this.columns = columns_operating
        }
    },
    methods: {
        get_info() {
            settlementDetail({
                data: {
                    id: this.$route.query.id,
                }
            }).then(res => {
                this.info = res.data.list

                let goods_form = [];
                this.info.goods_data.forEach(item => {
                    goods_form.push({
                        value: item.num
                    })
                })
                this.goods_form = goods_form;
            })
        },
        operating_change(value) {
            if (value == 2) {
                this.columns = columns_abnormal;
            } else {
                if (this.$route.query.type == 'abnormal') {
                    this.columns = columns_operating
                } else {
                    this.columns = columns;
                }

            }
        },
        handleSubmit() {
            let goods_list = [];
            this.info.goods_data.forEach((item, index) => {
                goods_list.push({
                    ...item,
                    user_num: this.goods_form[index].value,
                })
            })
            beginSettle({
                data: {
                    id: this.$route.query.id,
                    operate_status: this.operating_index,
                    goods_data: JSON.stringify(goods_list)
                },
                info: true
            }).then(res => {
                this.$router.back()
            })
        },
		grant_link(){
			this.$router.push('grant?grant_id='+this.info.grant_id)
		},
        back() {
            this.$router.back()
        }
    }
}
</script>

<style lang="less" scoped>

.title_h1 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding: 30px 0 50px;
}
</style>